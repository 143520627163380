.textLink {
  color: black;
  text-decoration: none;
}

.textLink:hover {
  color: black;
}
.hoverList:hover {
  background: black !important;
  color: white !important;
  cursor: pointer !important;
  border-radius: 8px !important;
  transition: color 0.3s ease-in-out !important;
}

/* .last ul li:last-child {
width: 200px !important;
} */
.menucss{

  /* border: 3px solid rgb(40, 36, 36); */
  background: rgb(88, 30, 30);
  color:white;
}
.menucss button:visited{
  background: rgb(12, 211, 211);
}

::-webkit-scrollbar {
    display: none;
}

.searchContainer{
  display: flex;
  justify-content: center;
  /* border: 1px solid red; */
  margin-bottom: 3px;

}
.searchInput{
 border:none;
 border-radius: 7px;
 background: beige;
 color: black;
 font-size: 1.1rem;

}
.searchtakeaway{
  border:none;
  border-radius: 7px;
  /* background: beige; */
  color: black;
  font-size: 1.1rem;
 
 }
.searchInput:focus {
  outline: none !important;
  background: white;
}
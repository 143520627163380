.form-1{
    background-color: beige;
    overflow-x: hidden;
    height: 90vh;
    display: flex;
    justify-content: center;
}
/* .forgot {
    background-color: #fff;
    padding: 12px;
    border: 1px solid #dfdfdf;
} */
/* .form-group{
    margin-top: 40px;
} */
.padding-bottom-3x {
    padding-bottom: 72px !important;
}

.card-footer {
    background-color: #fff;
}

.btn {

    font-size: 13px;
}

.form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #76b7e9;
    outline: 0;
    box-shadow: 0 0 0 0px #28a745;
}
.card-cs{
    width: 393px;
    height: 244px;
    position: relative;
    top: 95px;
    right:36px
}
.card-footer{
    display: flex;
    justify-content: space-between;
}

.inputContainer{
    display: flex;
    /* border: 2px solid; */
    position: relative;
    left: 34px;
}
.table-data{
    position: absolute;
    /* overflow-y: scroll; */
}


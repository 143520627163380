

.loginform {
  font-family: sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  height: 90vh;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  /* background-image: url(./img/resbg.jpg); */
  background-size:     cover;                     
  background-repeat:   no-repeat;
  background-position: center center;  
 

}
.error-img img{

 width: 60rem;
             
} 


.adminNav {
  padding: 2px;
  color: white;
  
  background-color: #272929;
}

/* input[type="text"],
input[type="password"] {
  height: 25px;
  border: 1px solid rgba(0, 0, 0, 0.2);
} */

input[type="submit"] {
  margin-top: 10px;
  cursor: pointer;
  font-size: 15px;
  background: #39c0d1;
  border: 2px solid #ade6ed;
  color: black;
  padding: 10px 20px;
}

input[type="submit"]:hover {
  background: #6cf0c2;
}

.button-container {
  display: flex;
  justify-content: center;
}

.login-form {
  background-color: #5e7b6d;
    padding: 2rem;
    /* box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%); */
    height: 25rem;
    color: white;
    border-radius: 29px;
    border: 2px solid rgb(248, 248, 248);
    font-size: 19px;
}

.list-container {
  display: flex;
}

.error {
  color: red;
  font-size: 12px;
}

.title {
  font-size: 25px;
  margin-bottom: 20px;
}

.input-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 10px;
}
.eye-img{
  width: 18px;
  position: relative;
  left: 187px;
  bottom: 26px;
}
.forgot{
  text-align: end;
  position: relative;
  bottom: 19px;
  font-size: 12px;
  
}
.flink{
  color: #dedede !important;
}



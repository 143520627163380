/* container ................................................................*/
.takeAwaycontainer {
  width: 70%;
  overflow: scroll;

  overflow: hidden;
  /* border:2px solid red; */
  align-items: center;
  display: flex;
  justify-content: center;
  height: max-content;
  flex-wrap: wrap;
}
.fullcontainer {
  display: flex;
  margin-top: 0.1rem;
  background: #e1dddd;
}

.leftcontainer {
  /* border :2px solid rgb(68, 68, 65); */
  /* background-color: #e5e5e5; */
  max-width: 500px;
  width: 29%;
  height: max-content;
}
.cartcontainer {
  /* border:2px solid rgb(0, 0, 0); */
  margin-top: 6px;
}
.cartcontainer h3 {
  text-align: start;
  padding: 18px;
}
.headeroffood h2 {
  /* border: 2px solid green; */
  color: rgb(0, 0, 0);
}
.navcont {
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 99;
  height: 41px;
  padding-top: 7px;
  background: rgb(106, 52, 52);
}
.hamburgermenu {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding-left: 1rem;
}
.logobrand {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding-left: 1rem;
  justify-content: space-between;
}

.secondnavcontainer {
  background: rgb(180, 148, 148);
  height: 2rem;
}
.secondnavinner {
  display: flex;
  justify-content: space-evenly;
  padding: 4px;
  max-width: 50rem;
}
.secondnavbtn {
  border: 1px solid #a15050;
  border-radius: 6px;
  font-size: 14px;
  color: #fffcfc;
  background: #a15050;
}
.secondnavbtn:hover {
  background-color: white;
  color: black;
}
.leftsidenav {
  border-left: 2px solid rgb(99, 99, 87);
  display: flex;
  font-size: 13px;
  color: white;
  width: 27rem;
  margin-right: 3rem;
  justify-content: space-evenly;
}
.input {
  height: 19px;
  background-color: #ab7171;
  color: white;
  border: none;
  padding: 9px;
  border-radius: 5px;
}
.radioBox {
  display: flex;
  justify-content: space-between;

  width: 9.7rem;
}
.liofsidebar {
  display: flex;
  margin-bottom: 12px;
  font-size: 17px;
  cursor: pointer;
}
.checkoutbtn button {
  border: none;
  border-radius: 6px;
  background: #1d68d7;
  color: white;
  font-size: 15px;
}
.loginheader {
  display: flex;

  /* border:2px solid ; */
  margin-left: 8px;
}
.loginheader button {
  margin-top: 2px;
  border: 1px solid #a15050;
  border-radius: 6px;
  font-size: 16px;
  color: #fffcfc;
  background: #a15050;
}
.loginContainer {
  background: #ebebde;

  height: 90vh;
}
/* .loginContainer2 {
  background: #ebebde;

  height: 118vh;
} */
a:hover{
  text-decoration: none !important;
}
.loginformcontainer {
  display: flex;
  justify-content: center;
}
.loginContainer h2 {
  margin-top: 5rem;
}
.forminput {
  border: 1px solid rgb(238, 231, 231);
  width: max-content;
  padding: 51px;
  height: max-content;
  border-radius: 11px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  background: #d7d2c2;
  margin-top: 7rem;
}
.forminput2 {
  /* border: 1px solid rgb(238, 231, 231); */
  width: max-content;
  padding: 24px;
  height: max-content;
  border-radius: 11px;
  /* box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px; */
  background: #d7d2c2;
  /* overflow-y: scroll; */
}
.Loginbtn {
  border: 1px solid #a15050;
  border-radius: 6px;
  font-size: 16px;
  color: #fffcfc;
  background: #a15050;
}
.otpcontainer {
  display: flex;
  justify-content: end;
  font-size: 12px;
  margin-top: 14px;
}
.otp {
  color: white;
  border: none;
  font-weight: 500;
  border-radius: 9px;
  background: #a15050;
}
.pickupCont {
  /* border:1px solid red; */
  font-size: 11px;
}
.TimeContainer {
  display: flex;
  justify-content: space-between;
}
.TimeSlot{
  font-weight: 500;
  font-size: 12px;
}
.TimeSlotChangeBtn {
  border: none;
  /* background: none; */
  color: white;
  font-weight: 600;
  position: relative;
  bottom: 2px;
  background: #a15050;
  border-radius: 4px;
  height: fit-content;
}
.collection {
  display: flex;
  background: #c7f1c7;
}
.para {
  padding-left: 12px;
}
.collectiontitle {
  font-weight: 700;
  font-size: 13px;
  text-decoration: underline;
}
.collectionpara2 {
  font-weight: 600;
  font-size: 11px;
}
.pickupcard {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  margin-top: 5px;
  padding: 2px;
  border-radius: 6px;
}
.pickupcardinner {
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  font-weight: 700;
}
.DESCRIPTIONPICKUP {
  /* border: 1px solid; */
  display: flex;
  flex-wrap: wrap;
  width: 8rem;
}

.promocode {
  margin-top: 8px;
  background-color: #f3f2f2;
  padding: 9px;
  border-radius: 3px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.inputpromocode {
  width: 55%;
  border-radius: 3px;
  background: #edebeb;
  border: 1px solid rgb(46, 41, 41);
  color: rgb(0, 0, 0);
}
.applypara {
  color: red;
  font-size: 13px;
  font-weight: 600;
  display: flex;
  justify-content: center;
}
.promocheckbtn {
  border: 1px solid #a15050;
  border-radius: 6px;
  font-size: 11px;
  color: #fffcfc;
  background: #a15050;
}
.promosearchAndBtn {
  display: flex;
  justify-content: space-evenly;
}
.subtotal {
  display: flex;
  justify-content: space-around;
  padding: 7px;
  margin-top: 9px;
  background-color: #a15050;
  color: white;
  font-size: 15px;
}
.inputDelviver {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  background: rgb(255, 255, 255);
  min-height: 3.43rem;
  box-shadow: rgb(177 206 236) 0px 1px 7px 1px;
  border-radius: 3px;
  position: relative;
  padding: 0.1rem 0px 0.2rem;
  width: 100%;
  justify-content: space-around;
}
.areaSearchBar {
  border-width: 0px;
  border: none;
  outline: none;
  font-size: 15px;
}
.areaSearchBar input:focus {
  border: none;
}
.locatemeBtn {
  border: 1px solid #a15050;
  border-radius: 3px;
  font-size: 13px;
  color: #fffcfc;
  background: #a15050;
}
.HeadOfDeliver {
  font-size: 13px;
  font-weight: 500;
}
.footerDeliver {
  position: absolute;
  top: 31rem;
  font-size: 13px;
  font-weight: 500;
  color: rgb(150, 147, 147);
}
/* {value.Product.Description} {value.Product.ProductQty} */

/* .trackcontainer {
  margin: 3.8rem 1rem 0px;
} */
.trackinner {
  display: flex;
  flex-flow: row wrap;
  right: 0px;
  left: 0px;
  margin: 1rem 0.4em 0px;
  max-width: 100%;
  flex-basis: auto;
  border-radius: 3px;
  background-color: rgb(255, 255, 255);
  box-shadow: rgb(0 0 0 / 12%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 2px 4px 0px;
  -webkit-box-pack: center;
  justify-content: center;
}
.inputcontainerTrack {
  line-height: 2;
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}
.head2track {
  position: relative;
  top: 14px;
  font-size: 0.9rem;
  color: grey;
}
.head1track {
  position: relative;
  top: 14px;
  font-size: 0.9rem;
  color: grey;
}
.trackbtn {
  margin-top: 12px;
  border: 1px solid #a15050;
  border-radius: 3px;
  font-size: 13px;
  color: #fffcfc;
  background: #a15050;
}
/* orderhistoryyyyy */
.show {
  margin-top: 2.5rem;
  /* border: 2px solid red; */
  height: 100vh;
}
.orderhistoryExplorebtn {
  margin-top: 12px;
  border: 1px solid #a15050;
  border-radius: 3px;
  font-size: 17px;
  color: #fffcfc;
  background: #a15050;
}
.orderhistorycardcontainer {
  margin-top: 2.6rem;
  /* height: 100vh; */
  /* border: 2px solid blue; */
  /* display: flex; */
  /* flex-direction: column; */
  background: rgb(242, 242, 242);
  flex-wrap: wrap;
}
.historycard {
  /* border: 1px solid grey; */
  background-color: rgb(255, 255, 255);
  box-shadow: rgb(0 0 0 / 12%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 2px 4px 0px;
  width: 24rem;
  height: max-content;
  margin: 1rem;
  padding: 4px;
}
.historyheader {
  display: flex;
  font-size: 0.7rem;
}
.itemshistory {
  display: flex;
  font-size: 0.7rem;
  flex-wrap: wrap;
  font-weight: 500;
}

.historybtn {
  display: flex;
  justify-content: center;
  margin-top: 12px;
}
.btnhistory {
  margin-top: 1px;
  border: 1px solid #a15050;
  border-radius: 3px;
  font-size: 11px;
  color: #fffcfc;
  background: #a15050;
}
.headingHistory {
  font-size: 2rem;
  color: grey;
  font-weight: 900;
}
.test {
  display: flex;
  flex-wrap: wrap;
}
.selectTimecont{
  /* border: 1px solid red; */
  display: flex;
  flex-wrap: wrap;
  margin-top: 1rem;
}
.btntime{
  margin:1rem;
}
.backbtnTime{
  margin-top: 1px;
    border: 1px solid #a15050;
    border-radius: 3px;
    font-size: 13px;
    color: #fffcfc;
    width: 3.2rem;
    background: #a15050;
    height: 1.4rem;
    position: relative;
    bottom: 2px;
}
.btntime{
  background: #ebe3e3;
  border: none;
  border-radius: 6px;
}
.btntime:hover{
  color: wheat;
  font-weight: 700;
  background: rgb(117, 116, 116);
}
.react-time-picker{
  margin-top: 4px !important;
  height: 46% !important;
}
.new-select{
  width: 238% !important;
}
.new-detail{
  width: 115%;
  margin-left: -33px !important;
}
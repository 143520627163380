.loader-wrapper {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(11, 11, 11, 0.5);
  display: grid;
  place-items: center;
  z-index: 21345678;
}
.loader {
  border: 16px solid #e3e3e3;
  border-top: 16px solid rgb(88, 30, 30);
  border-radius: 50%;
  width: 80px;
  height: 80px;
  z-index: 21345678;
  animation: spin 1s linear infinite;
}
.modaldiv {
  /* position: absolute;
    width: 500px;
    border-radius: 10px;
    z-index: 324567546;
    left: 50%;
    top: 320px;
    transform: translate(-50%,-50%);
    z-index: 21345678; */
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(11, 11, 11, 0.5);
  display: grid;
  place-items: center;
  z-index: 21345678;
}
.cardbox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  border: 2px solid white;
  padding: 20px;
  border-radius: 20px;
  background: #8e8e7c;
  width: 307px;
}
.erroriconbox,
.mainerrorclosebtn {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 50px;
}
.errorclosebtn {
  color: white;
  font-size: 1.4rem;
  background: #321515;
  width: 76px;
  height: 32px;
  /* padding: 0px; */
  text-align: center;
  border-radius: 24px;
}
.text-center {
  text-align: center !important;
}
.plusum {
  border: 1px solid rgb(137, 111, 111);
  height: fit-content;
  border-radius: 7px;
  font-size: 12px;
}

.buttoncontainer {
  display: flex;
  justify-content: space-evenly;
  /* flex-wrap: wrap; */
  font-size: 12px;
}
.headerCheck {
  /* border:1px solid red; */
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  width: 28rem;
}
.codeinput {
  display: flex;
  justify-content: space-around;
}
.desciptionCheck {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  width: 29rem;
  margin-top: 1rem;
  padding: 5px;
  font-size: 1.1rem;
}
.searchbtn{
  width: 82px;
  color: white;
  padding: 2px;
  border: none;
  background: rgb(88, 30, 30);
  border-radius: 6px;
  margin-top: 2px;
  margin-bottom: 6px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.cont-in{
    /* display: flex !important; */
    justify-content: space-evenly !important;
    /* position: relative; */
    /* right: 37px; */
}
/* .nav-link{
    position: relative;
    right: 28px;
} */
.userimg{
    width: 27px;
    /* position: relative;
    left: 13px; */
}
.focus{
    background-color: #ababab;
    border: none;
}
  .focus:focus {
    color: red;
  }
  /* .focus:hover  {
    background-color: black;
    /* border: none; */
  

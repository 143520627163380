table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
}

.printcontainer {
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: 15px;
  justify-content: center;
  line-height: 13px;
}

@media print {
  .hide-on-print {
    display: none;
  }
}
.printBtn {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}
.productList{
  display: flex;
  justify-content: center;
  width: 13rem;
  flex-wrap: wrap;
  line-height: 3.5px;
}
.productList1{
  display: flex;
  justify-content: center;
  /* width: 13rem; */
  flex-wrap: wrap;
  line-height: 3.5px;
}
/* .ptag p{
  margin-bottom: 2rem !important;
} */